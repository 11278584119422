// import React from 'react'
import { setDefaultBreakpoints } from 'react-socks';

setDefaultBreakpoints([
	{ xsmall: 0 }, // all mobile devices
	{ small: 576 }, // mobile devices (not sure which one's this big)
	{ medium: 772 }, // ipad, ipad pro, ipad mini, etc
	{ large: 1200 }, // smaller laptops
	{ xlarge: 1700 }
]);
