module.exports = [{
      plugin: require('/home/runner/work/aemc-der-microsite-2021/aemc-der-microsite-2021/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-ECR4QNB6R9","includeInDevelopment":true},
    },{
      plugin: require('/home/runner/work/aemc-der-microsite-2021/aemc-der-microsite-2021/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"aemc-der-2021","short_name":"aemc-der-2021","start_url":"https://new-energy-guide.aemc.gov.au/","background_color":"#ffffff","theme_color":"#005B82","display":"standalone","icon":"/home/runner/work/aemc-der-microsite-2021/aemc-der-microsite-2021/src/img/favicon-32x32.png"},
    },{
      plugin: require('/home/runner/work/aemc-der-microsite-2021/aemc-der-microsite-2021/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
